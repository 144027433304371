<template>
    <header class="header has-header-main-s1 bg-dark" id="home">
        <div class="header-main header-main-s1 is-sticky is-transparent on-dark">
            <div class="container header-container">
                <div class="header-wrap">
                    <!-- <div class="header-logo">
                                <a href="html/index.html" class="logo-link">
                                    <img class="logo-light logo-img" src="/images/icon/logo/1.png" srcset="/images/logo2x.png 2x" alt="logo">
                                    <img class="logo-dark logo-img" src="/images/icon/logo/2.png" srcset="/images/logo-dark2x.png 2x" alt="logo-dark">
                                </a>
                            </div> -->
                    <div class="header-logox pt-0">
                        <img src="/images/icon/logo/2.png" :style="{ height: '65px', width: '280px' }" alt="">
                    </div>
                    <div class="header-toggle">
                        <button class="menu-toggler" data-target="mainNav">
                            <em class="menu-on icon ni ni-menu"></em>
                            <em class="menu-off icon ni ni-cross"></em>
                        </button>
                    </div><!-- .header-nav-toggle -->
                    <nav class="header-menu" data-content="mainNav">
                        <ul class="menu-list ms-lg-auto">
                            <li class="menu-item"><a href="#home" class="menu-link nav-link">Services</a></li>
                            <li class="menu-item"><a href="#home" class="menu-link nav-link">Pricing</a></li>

                            <li class="menu-item has-sub">
                                <a href="#" class="menu-link menu-toggle">Membership</a>
                                <div class="menu-sub">
                                    <ul class="menu-list">
                                        <li class="menu-item">
                                            <a href="html/index.html" class="menu-link">
                                                Client Inquiry
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="menu-item"><a href="#home" class="menu-link nav-link">Stores</a></li>
                            <li class="menu-item"><a href="#home" class="menu-link nav-link">FAQ</a></li>
                            <ul class="menu-btns">
                                <li>
                                    <a href="#" target="_blank"
                                        class="btn btn-primary btn-round btn-lg">Download App</a>
                                </li>
                            </ul>
                            <ul class="menu-btns">
                                <li>
                                    <a href="#" target="_blank"
                                        class="btn btn-light bg-white btn-round btn-lg">
                                        <em class="icon ni ni-user"></em> &ensp;
                                        Dashboard
                                    </a>
                                </li>
                            </ul>
                            <!--                             
                            <li class="menu-item"><a href="#home" class="menu-link nav-link">Home</a></li>
                            <li class="menu-item"><a href="#feature" class="menu-link nav-link">Features</a></li>
                            <li class="menu-item"><a href="#package" class="menu-link nav-link">Packages</a></li>
                            <li class="menu-item"><a href="#team" class="menu-link nav-link">Team</a></li>
                            <li class="menu-item"><a href="#reviews" class="menu-link nav-link">Reviews</a></li> -->
                        </ul>
                    </nav><!-- .nk-nav-menu -->
                </div><!-- .header-warp-->
            </div><!-- .container-->
        </div><!-- .header-main-->
        <div class="header-content my-auto py-6 is-dark">
            <div class="container mt-n4 mt-lg-0">
                <div class="row flex-lg-row-reverse align-items-center justify-content-between g-gs">
                    <!-- .col- -->
                    <div class="col-lg-6 col-md-10">
                        <!-- <img src="/GalaxyStore_English.png" :style="{ height: '32px' }" alt=""> &ensp;
                        <img src="/Google_Play_Store_badge_EN.svg.webp" :style="{ height: '32px' }" alt=""> &ensp;
                        <img src="/Download_on_the_App_Store_Badge.svg.png" :style="{ height: '32px' }" alt="">
                        <br><br><br> -->
                        <div class="header-caption">
                            <div class="header-rating rating">
                                <ul class="rating-stars">
                                    <li><em class="icon ni ni-star-fill"></em></li>
                                    <li><em class="icon ni ni-star-fill"></em></li>
                                    <li><em class="icon ni ni-star-fill"></em></li>
                                    <li><em class="icon ni ni-star-fill"></em></li>
                                    <li><em class="icon ni ni-star-half-fill"></em></li>
                                </ul>
                                <div class="rating-text">Client Trust Average <b class="text-whte">4.9</b> out of 5
                                    stars.</div>
                            </div>
                            <span class="text-white fw-700" :style="{ fontSize: '34x' }">🧺 Shop for groceries from the
                                comfort of your home. 🔥</span>
                            <h1 class="header-titlex text-white fw-bold"
                                :style="{ fontFamily: 'Poppins', color: '#fff' }">
                                Your <span class="brush-paint">Local Market</span>,<br> Online and Fast.
                            </h1>

                            <div class="header-text pb-0 mb-4 text-white">
                                <p :style="{ textAlign: 'justify' }">
                                    Experience the ease of online grocery shopping with
                                    quick and reliable delivery. Get the best produce and grocery items from local
                                    markets delivered to you.
                                </p>
                            </div>

                            <div class="col-md-12">
                                <div class="form-group mb-4">
                                    <div class="form-control-wrap">
                                        <div class="form-icon form-icon-right">
                                            <em class="icon ni ni-map-pin"></em>
                                        </div>
                                        <input type="text" class="form-control form-control-lg"
                                            :style="{ borderRadius: '20px' }" id="default-04"
                                            placeholder="Find stores in your location here..">
                                    </div>
                                </div>
                            </div>


                            <ul class="header-action btns-inline">
                                <li><a href="#" class="btn btn-success btn-round btn-lg text-white">
                                        <em class="icon ni ni-map-pin"></em>
                                        <span class="fw-normal">
                                            Find Stores Near You
                                        </span>
                                    </a></li>
                                <li><a href="#" class="link link-block link-gray"><em
                                            class="icon icon-lg ni ni-play-circle"></em><span>Watch video</span></a>
                                </li>
                            </ul>
                        </div>
                    </div><!-- .col -->
                    <div class="col-lg-6 mb-lg-0">
                        <img src="/images/bg/x.png" class="img-hero" alt="">
                    </div>
                </div><!-- .row -->
            </div><!-- .container -->
        </div><!-- .header-content -->
        <div class="bg-image bg-overlay after-bg-dark after-opacity-95">
            <img src="/images/bg/a.jpg" alt="">
        </div>
    </header>
    <!-- <header class="header has-header-main-s1" id="home" :style="{ backgroundColor: '#F5F5F5', }">

        <div class="header-main header-main-s1 is-sticky is-transparent shadow m-0 p-0"
            :style="{ backgroundColor: '#FEFEFF', color: '#fff' }">
            <div class="container header-container"
                :style="{ zIndex: '9999', backgroundColor: '#fff' }">
                <div class="header-wrap">
                    <div class="header-logox pt-0">
                        <img src="/logo.png" :style="{ height: '85px', width: '350px' }" alt="">
                    </div>
                    <div class="header-toggle">
                        <button class="menu-toggler" data-target="mainNav">
                            <em class="menu-on icon ni ni-menu"></em>
                            <em class="menu-off icon ni ni-cross"></em>
                        </button>
                    </div>
                    <nav class="header-menu" data-content="mainNav">
                        <ul class="menu-list ms-lg-auto">
                            <li class="menu-item"><a href="#home" class="menu-link nav-link">Sevices</a></li>
                            <li class="menu-item"><a href="#home" class="menu-link nav-link">Stores</a></li>

                            <li class="menu-item has-sub">
                                <a href="#" class="menu-link menu-toggle">Membership</a>
                                <div class="menu-sub">
                                    <ul class="menu-list">
                                        <li class="menu-item">
                                            <a href="html/index.html" class="menu-link">Landing Page -
                                                v1
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="menu-item">
                                <a href="#home" class="menu-link">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <div class="form-icon form-icon-right">
                                                <em class="icon ni ni-search"></em>
                                            </div>
                                            <input type="text" class="form-control form-control-lg" :style="{borderRadius: '20px'}"
                                                placeholder="Search anything here..">
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <ul class="menu-btns">
                                <li>
                                    <a href="https://1.envato.market/e0y3g" target="_blank"
                                        class="btn btn-light bg-white btn-round btn-lg">
                                        <em class="icon ni ni-user"></em> &ensp;
                                        Sign In
                                    </a>
                                </li>
                            </ul>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div class="header-content my-auto py-5">
            <div class="container" >
                <div class="row flex-lg-row-reverse align-items-center justify-content-between g-gs"
                    :style="{ position: 'relative', zIndex: '0' }">
                    <div class="col-lg-12 mb-n3 mb-lg-0" :style="{ position: 'absolute', zIndex: '0' }">
                        <div class="header-image header-image-s2 ">
                            <img src="/banner.png" alt="">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-10"></div>
                    <div class="col-lg-6 col-md-10" :style="{ position: 'relative', zIndex: '9998' }">
                        
                        <img src="/GalaxyStore_English.png" :style="{ height: '32px' }" alt=""> &ensp;
                        <img src="/Google_Play_Store_badge_EN.svg.webp" :style="{ height: '32px' }" alt=""> &ensp;
                        <img src="/Download_on_the_App_Store_Badge.svg.png" :style="{ height: '32px' }" alt="">
                        <br><br><br>
                        <div class="header-caption">
                            <div class="header-rating rating">
                                <ul class="rating-stars">
                                    <li><em class="icon ni ni-star-fill"></em></li>
                                    <li><em class="icon ni ni-star-fill"></em></li>
                                    <li><em class="icon ni ni-star-fill"></em></li>
                                    <li><em class="icon ni ni-star-fill"></em></li>
                                    <li><em class="icon ni ni-star-half-fill"></em></li>
                                </ul>
                                <div class="rating-text">Client Trust Average <b class="text-dark">4.9</b> out of 5
                                    stars.</div>
                            </div>
                            <span class="text-dark fw-700" :style="{ fontSize: '34x' }">🧺 Shop for groceries from the
                                comfort of your home. 🔥</span>
                            <h1 class="header-titlex text-dark fw-bold"
                                :style="{ fontFamily: 'Poppins', color: '#000' }">
                                Your <span class="brush-paint">Local Market</span>,<br> Online and Fast.
                            </h1> 

                            <div class="header-text pb-0 mb-4 text-dark">
                                <p :style="{ textAlign: 'justify' }">
                                    Experience the ease of online grocery shopping with
                                    quick and reliable delivery. Get the best produce and grocery items from local
                                    markets delivered to you.
                                </p>
                            </div>

                            <div class="col-md-10">
                                <div class="form-group mb-4">
                                    <div class="form-control-wrap">
                                        <div class="form-icon form-icon-right">
                                            <em class="icon ni ni-map-pin"></em>
                                        </div>
                                        <input type="text" class="form-control form-control-lg" id="default-04"
                                            placeholder="Find stores in your location here..">
                                    </div>
                                </div>
                            </div>


                            <ul class="header-action btns-inline">
                                <li><a href="#" class="btn btn-secondary btn-round btn-lg text-white">
                                        <em class="icon ni ni-map-pin"></em>
                                        <span class="fw-normal">
                                            Find Stores Near You
                                        </span>
                                    </a></li>
                                <li><a href="#" class="link link-block link-gray"><em
                                            class="icon icon-lg ni ni-play-circle"></em><span>Watch video</span></a>
                                </li>
                            </ul>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </header> -->
</template>
<style>
.text-container {
    font-size: 16px;
    line-height: 1.5;
}

.img-hero {
    height: 625px;
    border-bottom-right-radius: 20%;
}

.brush-paint {
    position: relative;
    display: inline-block;
    padding: 0 8px;
    background: linear-gradient(to top, #8c7ae0 50%, rgba(255, 255, 255, 0) 50%);
    background-size: 100% 100px;
    background-repeat: no-repeat;
}
</style>
<script>
export default { name: 'HeaderLayout' } 
</script>