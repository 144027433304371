<template>
  <!DOCTYPE html>
  <html lang="zxx" class="js">
  <HeadLayout />

  <body class="nk-body bg-white npc-landing ">
    <div class="nk-app-root">
      <!-- main @s -->
      <div class="nk-main ">
        <HeaderLayout />
        <!-- .header -->
        
    <img src="/backdrop/shape-08.svg" alt="" :style="{ position: 'absolute', zIndex: '0' }">
    <img src="/backdrop/shape-15.svg" alt="" :style="{ position: 'absolute', zIndex: '0' }">
    <img src="/backdrop/shape-12.svg" alt="" :style="{ position: 'absolute', zIndex: '0' }">
        <section class="section section-feature pb-0" id="feature"  :style="{ position: 'relative', zIndex: '1' }">
          <div class="container">
            <div class="row align-items-center justify-content-lg-between g-gs">
              <div class="col-lg-5">
                <div class="img-block img-block-s1 left">
                  <img src="/images/brand/11.jpg" alt="img" :style="{ borderRadius: '60px 120px 0' }" />
                </div><!-- .img-block -->
              </div><!-- .col -->
              <div class="col-lg-6">
                <div class="text-block">
                  <h2 class="title">Check Our Offers & Services</h2>
                  <div class="g-gs">
                    <div class="service service-s3">
                      <div class="service-icon styled-icon styled-icon-s3 text-primary">
                        <svg x="0px" y="0px" viewBox="0 0 512 512" style="fill:currentColor" xml:space="preserve">
                          <path
                            d="M436.6,175c-26.7-22.5-53.8-40.5-80.7-53.3C322,105.4,288.4,97.2,256,97.2s-66,8.2-99.9,24.5
	c-26.9,12.9-54.1,30.8-80.7,53.3c-45.3,38.2-73,75.8-74.2,77.4c-1.6,2.1-1.6,5.1,0,7.2c1.2,1.6,28.9,39.2,74.2,77.4
	c26.7,22.5,53.8,40.5,80.7,53.3c33.9,16.2,67.5,24.5,99.9,24.5s66-8.2,99.9-24.5c26.9-12.9,54.1-30.8,80.7-53.3
	c45.3-38.2,73-75.8,74.2-77.4c1.6-2.1,1.6-5.1,0-7.2C509.7,250.8,481.9,213.2,436.6,175L436.6,175z M428.7,327.7
	c-25.9,21.8-52.1,39.2-78.1,51.7c-32.2,15.4-64.1,23.3-94.6,23.3c-30.5,0-62.3-7.8-94.5-23.2c-26-12.4-52.2-29.8-78-51.6
	c-25.5-21.6-48.9-45.7-69.7-71.9c20.7-26.1,44-50.1,69.5-71.7c25.9-21.8,52.1-39.2,78.1-51.7c32.2-15.4,64.1-23.3,94.6-23.3
	c30.5,0,62.3,7.8,94.5,23.2c26,12.4,52.2,29.8,78,51.6c25.5,21.6,48.9,45.7,69.7,71.9C477.5,282.1,454.2,306.1,428.7,327.7z" />
                          <path d="M249.9,20.1v54.6c0,3.4,2.7,6.1,6.1,6.1c3.4,0,6.1-2.7,6.1-6.1V20.1c0-3.4-2.7-6.1-6.1-6.1C252.6,14,249.9,16.7,249.9,20.1z
	" />
                          <path d="M444.3,73.9l-42.8,42.6c-2.4,2.4-2.4,6.2,0,8.6c2.4,2.4,6.3,2.4,8.7,0L453,82.5c2.4-2.4,2.3-6.3-0.1-8.6
	C450.5,71.5,446.7,71.5,444.3,73.9z" />
                          <path d="M101.8,125.1c2.4,2.4,6.3,2.4,8.7,0s2.4-6.2,0-8.6L67.7,73.9c-2.4-2.4-6.3-2.3-8.7,0.1c-2.3,2.4-2.3,6.2,0,8.6L101.8,125.1z
	" />
                          <path d="M256,431.2c-3.4,0-6.1,2.7-6.1,6.1v54.6c0,3.4,2.7,6.1,6.1,6.1c3.4,0,6.1-2.7,6.1-6.1v-54.6
	C262.1,433.9,259.4,431.2,256,431.2z" />
                          <path d="M101.8,386.9L59,429.5c-2.4,2.4-2.3,6.3,0.1,8.6c2.4,2.3,6.2,2.3,8.6,0l42.8-42.6c2.4-2.4,2.4-6.2,0-8.6
	C108,384.5,104.2,384.5,101.8,386.9C101.8,386.9,101.8,386.9,101.8,386.9z" />
                          <path d="M410.2,386.9c-2.4-2.4-6.3-2.4-8.7,0c-2.4,2.4-2.4,6.2,0,8.6l42.8,42.6c2.4,2.4,6.3,2.3,8.7-0.1c2.3-2.4,2.3-6.2,0-8.6
	L410.2,386.9z" />
                          <path
                            d="M74.3,208.5c-14.1,13.2-27.5,27.1-40.1,41.8c-2.2,2.6-1.9,6.4,0.7,8.6c2.6,2.2,6.4,1.9,8.6-0.7
	c12.3-14.3,25.3-27.9,39.1-40.8c2.5-2.3,2.7-6.1,0.4-8.6S76.9,206.1,74.3,208.5C74.4,208.4,74.4,208.5,74.3,208.5L74.3,208.5z" />
                          <path d="M93.8,191.3c-1.2,1-2.4,2-3.6,3.1c-2.6,2.2-2.9,6-0.7,8.6c2.2,2.6,6.1,2.9,8.6,0.7c1.2-1,2.3-2,3.5-3c2.6-2.2,2.9-6,0.8-8.6
	C100.3,189.5,96.4,189.2,93.8,191.3z" />
                          <path d="M259.3,113.3c-30.8-0.7-61.1,8.5-86.2,26.4c-2.8,1.9-3.4,5.7-1.5,8.5c1.9,2.8,5.8,3.4,8.5,1.5c0,0,0,0,0.1-0.1
	c23.1-16.5,51.1-24.9,79.5-24.1c68.9,1.9,124.9,57.4,127.2,126.1c2.5,74.4-57.9,135.7-132.2,135c-70.7-0.7-128.5-58-129.6-128.4
	c-0.5-28.8,8.6-56.9,25.9-80c1.8-2.4,1.6-5.8-0.6-8c-2.4-2.4-6.3-2.4-8.7,0c-0.2,0.2-0.4,0.4-0.6,0.7
	c-19.4,25.9-29.4,57.7-28.2,90.1c2.5,75.3,64.3,136.2,139.9,137.8c81.5,1.8,148.2-64.7,146.4-145.9
	C397.5,176.8,335.5,115,259.3,113.3L259.3,113.3z" />
                          <path d="M256.6,308c29.8,0,53.9-24.1,53.9-53.7c0-29.7-24.1-53.7-53.9-53.7c-29.8,0-53.9,24.1-53.9,53.7c0,0,0,0,0,0
	C202.7,283.9,226.8,308,256.6,308z M256.6,212.7c23,0,41.7,18.6,41.7,41.5c0,22.9-18.7,41.5-41.7,41.5c-23,0-41.7-18.6-41.7-41.5
	c0,0,0,0,0,0C215,231.3,233.6,212.8,256.6,212.7z" />
                          <path d="M256.6,277.1c12.6,0,22.9-10.2,22.9-22.8c0-12.6-10.3-22.8-22.9-22.8s-22.9,10.2-22.9,22.8c0,0,0,0,0,0
	C233.7,266.9,243.9,277.1,256.6,277.1z M256.6,243.6c5.9,0,10.7,4.8,10.7,10.6c0,5.9-4.8,10.6-10.7,10.6c-5.9,0-10.7-4.8-10.7-10.6
	c0,0,0,0,0,0C245.9,248.4,250.7,243.6,256.6,243.6z" />
                          <path d="M160,272.6l19.9,3.2c1.7,6,4.1,11.9,7.1,17.3l-12,16.4c-1.8,2.4-1.5,5.8,0.6,7.9l17.5,17.5c2.1,2.1,5.5,2.4,7.9,0.6
	l16.4-11.9c5.5,3.1,11.3,5.5,17.4,7.2l3.2,19.8c0.5,2.9,3.1,5.1,6,5H269c3,0.1,5.6-2.1,6-5l3.2-19.9c6.1-1.7,11.9-4.1,17.4-7.1
	l16.4,11.9c2.4,1.8,5.8,1.5,7.9-0.6l17.5-17.5c2.1-2.1,2.4-5.5,0.6-7.9l-11.9-16.4c3.1-5.5,5.5-11.3,7.2-17.3l20.3-3.2
	c3-0.5,5.3-3,5.4-6v-24.7c-0.1-3-2.4-5.6-5.4-6l-20.2-3.2c-1.7-6-4.2-11.9-7.3-17.3l11.9-16.4c1.8-2.4,1.5-5.8-0.6-7.9l-17.5-17.5
	c-2.1-2.1-5.5-2.4-7.9-0.6l-16.4,11.9c-5.5-3.1-11.3-5.5-17.4-7.2l-3.2-20.1c-0.5-3-3-5.2-6-5.3h-24.8c-3,0.1-5.6,2.3-6,5.3
	l-3.2,20.1c-6.1,1.7-11.9,4.1-17.4,7.2L201.1,173c-2.4-1.8-5.8-1.5-7.9,0.6l-17.5,17.5c-2.1,2.1-2.4,5.5-0.6,7.9l11.9,16.4
	c-3.1,5.5-5.5,11.3-7.2,17.3l-19.8,3.2c-2.9,0.5-5,3.1-4.9,6v24.7C154.9,269.6,157,272.1,160,272.6L160,272.6z M167.3,247.1l18.7-3
	c2.5-0.4,4.4-2.3,4.9-4.7c1.7-7.5,4.6-14.6,8.7-21.1c1.3-2.1,1.2-4.8-0.2-6.8L188.1,196l10.1-10.1l15.5,11.2c2,1.5,4.7,1.6,6.9,0.2
	c6.5-4.1,13.7-7.1,21.2-8.8c2.5-0.6,4.3-2.6,4.7-5.1l3-19h14.3l3,19c0.4,2.5,2.2,4.5,4.7,5.1c7.5,1.7,14.7,4.7,21.2,8.8
	c2.1,1.3,4.8,1.3,6.9-0.2l15.5-11.2l10.1,10.1l-11.2,15.4c-1.5,2-1.6,4.7-0.2,6.8c4.1,6.5,7.1,13.6,8.8,21.1
	c0.6,2.5,2.7,4.3,5.2,4.7l19.1,3v14.3l-19.1,3c-2.5,0.4-4.5,2.2-5.1,4.7c-1.7,7.5-4.7,14.6-8.8,21.1c-1.3,2.1-1.3,4.8,0.2,6.8
	l11.2,15.4L315,322.6l-15.5-11.2c-2-1.5-4.8-1.6-6.9-0.2c-6.5,4.1-13.7,7.1-21.2,8.8c-2.4,0.5-4.3,2.4-4.7,4.8l-3,18.7h-14.3
	l-3-18.7c-0.4-2.5-2.3-4.4-4.7-4.9c-7.5-1.7-14.7-4.6-21.2-8.7c-2.1-1.3-4.8-1.2-6.9,0.2l-15.5,11.2l-10.1-10.1l11.2-15.4
	c1.5-2,1.6-4.7,0.2-6.8c-4.1-6.5-7.1-13.6-8.8-21.1c-0.5-2.4-2.4-4.3-4.8-4.7l-18.7-3L167.3,247.1z" />
                        </svg>
                      </div>
                      <div class="service-text">
                        <h4 class="title">Grocery & Food Delivery</h4>
                        <p>Deliver groceries and meals from local stores with inventory management and real-time order tracking.</p>
                      </div>
                    </div>
                    <div class="service service-s3">
                      <div class="service-icon styled-icon styled-icon-s3 text-success">
                        <svg x="0px" y="0px" viewBox="0 0 512 512" style="fill:currentColor" xml:space="preserve">
                          <g>
                            <g>
                              <path d="M346.8,337.2h-200c-2.1,0-4.2,0.8-5.7,2.3l-96,96l11.3,11.3l93.7-93.7H338c-1.7,9.5-6.9,24-23.2,24h-88v16h88
			c31.6,0,40-31.4,40-48C354.8,340.8,351.2,337.2,346.8,337.2z" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <path
                                d="M448.5,283.5c-3.1-3.1-8.2-3.1-11.3,0l-72,72l11.3,11.3l65.7-65.7c10.8,14.4,8.6,34.7-5,46.4l-93.7,93.7H178.8
			c-2.1,0-4.2,0.8-5.7,2.3l-56,56l11.3,11.3l53.7-53.7h164.7c2.1,0,4.2-0.8,5.7-2.3l96-96C478.3,329,461.5,296.5,448.5,283.5z" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M322.8,249.2c-7.5,0-14.4,2.6-19.8,6.9c-6.4-13.5-20.2-22.9-36.2-22.9s-29.8,9.4-36.2,22.9c-5.5-4.3-12.3-6.9-19.8-6.9
			c-17.7,0-32,14.3-32,32s14.3,32,32,32c10.8,0,20.3-5.3,26.1-13.5c7.3,8.3,18,13.5,29.9,13.5c11.9,0,22.6-5.2,29.9-13.5
			c5.8,8.2,15.3,13.5,26.1,13.5c17.7,0,32-14.3,32-32C354.8,263.5,340.5,249.2,322.8,249.2z M210.8,297.2c-8.8,0-16-7.2-16-16
			s7.2-16,16-16s16,7.2,16,16S219.7,297.2,210.8,297.2z M266.8,297.2c-13.3,0-24-10.7-24-24s10.7-24,24-24s24,10.7,24,24
			S280.1,297.2,266.8,297.2z M322.8,297.2c-8.8,0-16-7.2-16-16s7.2-16,16-16s16,7.2,16,16S331.7,297.2,322.8,297.2z" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M464.5,107.5l-40-40l-11.3,11.3l26.3,26.3H330.8c-0.2,0-0.4,0-0.7,0c-8.7,0.2-15.5,7.3-15.3,16v48h16v-48h108.7
			l-26.3,26.3l11.3,11.3l40-40C467.6,115.7,467.6,110.6,464.5,107.5z" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <path
                                d="M272.5,3.5c-3.1-3.1-8.2-3.1-11.3,0l-40,40l11.3,11.3l26.3-26.3v124.7h16V28.5l26.3,26.3l11.3-11.3L272.5,3.5z" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <rect x="314.8" y="185.2" width="16" height="48" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <rect x="202.8" y="185.2" width="16" height="48" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <rect x="258.8" y="169.2" width="16" height="48" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M202.8,105.2H94.1l26.3-26.3l-11.3-11.3l-40,40c-3.1,3.1-3.1,8.2,0,11.3l40,40l11.3-11.3l-26.3-26.3h108.7v48h16v-48
			c0-0.2,0-0.4,0-0.7C218.6,111.9,211.5,105,202.8,105.2z" />
                            </g>
                          </g>
                        </svg>
                      </div>
                      <div class="service-text">
                        <h4 class="title">Courier & Parcel Delivery</h4>
                        <p>Offer pick-up and delivery services for parcels with optimized route planning and scheduling.</p>
                      </div>
                    </div><!-- .service -->
                    <div class="service service-s3">
                      <div class="service-icon styled-icon styled-icon-s3 text-success">
                        <svg x="0px" y="0px" viewBox="0 0 512 512" style="fill:currentColor" xml:space="preserve">
                          <g>
                            <g>
                              <path d="M346.8,337.2h-200c-2.1,0-4.2,0.8-5.7,2.3l-96,96l11.3,11.3l93.7-93.7H338c-1.7,9.5-6.9,24-23.2,24h-88v16h88
			c31.6,0,40-31.4,40-48C354.8,340.8,351.2,337.2,346.8,337.2z" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <path
                                d="M448.5,283.5c-3.1-3.1-8.2-3.1-11.3,0l-72,72l11.3,11.3l65.7-65.7c10.8,14.4,8.6,34.7-5,46.4l-93.7,93.7H178.8
			c-2.1,0-4.2,0.8-5.7,2.3l-56,56l11.3,11.3l53.7-53.7h164.7c2.1,0,4.2-0.8,5.7-2.3l96-96C478.3,329,461.5,296.5,448.5,283.5z" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M322.8,249.2c-7.5,0-14.4,2.6-19.8,6.9c-6.4-13.5-20.2-22.9-36.2-22.9s-29.8,9.4-36.2,22.9c-5.5-4.3-12.3-6.9-19.8-6.9
			c-17.7,0-32,14.3-32,32s14.3,32,32,32c10.8,0,20.3-5.3,26.1-13.5c7.3,8.3,18,13.5,29.9,13.5c11.9,0,22.6-5.2,29.9-13.5
			c5.8,8.2,15.3,13.5,26.1,13.5c17.7,0,32-14.3,32-32C354.8,263.5,340.5,249.2,322.8,249.2z M210.8,297.2c-8.8,0-16-7.2-16-16
			s7.2-16,16-16s16,7.2,16,16S219.7,297.2,210.8,297.2z M266.8,297.2c-13.3,0-24-10.7-24-24s10.7-24,24-24s24,10.7,24,24
			S280.1,297.2,266.8,297.2z M322.8,297.2c-8.8,0-16-7.2-16-16s7.2-16,16-16s16,7.2,16,16S331.7,297.2,322.8,297.2z" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M464.5,107.5l-40-40l-11.3,11.3l26.3,26.3H330.8c-0.2,0-0.4,0-0.7,0c-8.7,0.2-15.5,7.3-15.3,16v48h16v-48h108.7
			l-26.3,26.3l11.3,11.3l40-40C467.6,115.7,467.6,110.6,464.5,107.5z" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <path
                                d="M272.5,3.5c-3.1-3.1-8.2-3.1-11.3,0l-40,40l11.3,11.3l26.3-26.3v124.7h16V28.5l26.3,26.3l11.3-11.3L272.5,3.5z" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <rect x="314.8" y="185.2" width="16" height="48" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <rect x="202.8" y="185.2" width="16" height="48" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <rect x="258.8" y="169.2" width="16" height="48" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M202.8,105.2H94.1l26.3-26.3l-11.3-11.3l-40,40c-3.1,3.1-3.1,8.2,0,11.3l40,40l11.3-11.3l-26.3-26.3h108.7v48h16v-48
			c0-0.2,0-0.4,0-0.7C218.6,111.9,211.5,105,202.8,105.2z" />
                            </g>
                          </g>
                        </svg>
                      </div>
                      <div class="service-text">
                        <h4 class="title">Pharmacy Delivery</h4>
                        <p>Provide prescription and over-the-counter medication delivery with automated refill reminders.</p>
                      </div>
                    </div>
                    
                  </div>
                </div><!-- .text-block -->

              </div><!-- .col -->
            </div><!-- .row -->
          </div><!-- .container -->
        </section><!-- .section -->
        <section class="section section-feature pb-0">
          <div class="container">
            <div class="row flex-row-reverse align-items-center justify-content-between g-gs">
              <div class="col-lg-5">
                <div class="img-block img-block-s1 right">
                  <img src="/images/brand/3.jpg" alt="img" :style="{ borderRadius: '0 120px 80px' }">
                </div><!-- .img-block -->
              </div><!-- .col -->
              <div class="col-lg-6">
                <div class="text-block">
                  <h2 class="title">Transforming Local Delivery with Convenience and Quality</h2>
                  <p  class="lead" :style="{textAlign: 'justify'}">Our mission is to deliver fast, reliable, and convenient services for essentials like groceries, pharmacy products, fresh produce, and courier needs. We aim to transform local delivery by empowering businesses and enhancing daily life through advanced technology and exceptional customer service. </p>
                  <ul class="btns-inline">
                    <li><a href="#"><img src="/GalaxyStore_English.png" :style="{ height: '42px' }" alt=""> </a></li>
                    <li><a href="#"><img src="/Google_Play_Store_badge_EN.svg.webp" :style="{ height: '40px' }" alt=""> </a></li>
                    <li><a href="#"><img src="/Download_on_the_App_Store_Badge.svg.png" :style="{ height: '40px' }" alt=""> </a></li>
                  </ul>
                </div><!-- .text-block -->

              </div><!-- .col -->
            </div><!-- .row -->
          </div><!-- .container -->
        </section><!-- .section -->
        <section class="section section-feature">
          <div class="container">
            <div class="row align-items-center justify-content-between g-gs">
              <div class="col-lg-5">
                <div class="img-block img-block-s1 left">
                  <img src="/images/brand/14.jpg" alt="img" :style="{ borderRadius: '80px 0 80px' }">
                </div>
              </div><!-- .col -->
              <div class="col-lg-6">
                <div class="text-block me-xl-5">
                  <h2 class="title">We Provide <span class="text-primary">Elderly-Friendly</span></h2>
                  <div class="review review-s3">
                    <div class="review-content">
                      <div class="review-rating rating rating-sm">
                        <ul class="rating-stars">
                          <li><em class="icon ni ni-star-fill"></em></li>
                          <li><em class="icon ni ni-star-fill"></em></li>
                          <li><em class="icon ni ni-star-fill"></em></li>
                          <li><em class="icon ni ni-star-fill"></em></li>
                          <li><em class="icon ni ni-star-fill"></em></li>
                        </ul>
                      </div>
                      <div class="review-text">
                        <p>Online pharmacy delivery has made my life so much easier! I can order my meds in minutes and
                          get them delivered to my door. The refill reminders are a lifesaver, and it's super convenient
                          and reliable.</p>
                        <h6 class="review-name text-dark">~ Mrs. Rose D. Quezon<br><small><i>satisfied customer</i></small>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div><!-- .text-block -->
              </div><!-- .col -->
            </div><!-- .row -->
          </div><!-- .container -->
        </section><!-- .section -->

        <section class="section pb-5 pt-5 bg-light" id="package">
          <div class="container">
            <div class="row justify-content-center text-tenter">
              <div class="col-xl-7 col-lg-9">
                <div class="section-head text-center mb-1">
                  <h2 class="title text-dark mb-0">Best Package to Start Your Business</h2>
                  <p>Optimize your network with a virtual strategy that addresses various potential opportunities. Efficiently engage with targeted e-tailers without requiring premium solutions.</p>
                </div>
              </div><!-- .col -->
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-10">
                <div class="row align-items-center g-0">
                  <div class="col-md-7">
                    <div class="card card-shadow round-xl bg-dark is-dark pb-4 pb-md-0">
                      <div class="card-inner card-inner-xl">
                        <div class="text-block">
                          <h3 class="title mb-3">Advanced Pack</h3>
                          <ul class="list list-nostyle fs-15px mb-1">
                            <li>Priority delivery for faster service</li>
                            <li>Extended customer support hours</li>
                            <li>Personalized delivery options</li>
                            <li>Enhanced tracking and updates</li>
                            <li>Exclusive discounts on select items</li>
                            <li class="note text-warning fs-14px">+ Customize POS and Inventory System </li>
                          </ul>
                          <ul class="btns-inline pt-4">
                            <li><a href="#" class="btn btn-round btn-xl btn-primary fs-16px">Subscribe Now for ₱ 4,550.00</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div><!-- .col -->
                  <div class="col-md-5">
                    <div
                      class="card card-shadow card-bd-right-3px round-xl ms-lg-n7 ms-md-n5 mx-4 me-md-0 mt-md-0 mt-n4">
                      <div class="card-inner card-inner-lg">
                        <div class="text-block is-compact pe-3">
                          <h4 class="title text-azure">Starter Pack</h4>
                          <ul class="list list-nostyle fs-15px">
                            <li>Standard delivery services</li>
                            <li>Basic tracking and updates</li>
                            <li>Regular customer support</li>
                            <li>+ POS and Inventory System Included</li>
                          </ul>
                          <ul class="btns-inline pt-2">
                            <li><a href="#" class="btn btn-lg btn-round btn-secondary"> <span>Subscribe Now for
                              ₱ 2,329.00</span><em class="icon ni ni-arrow-long-right"></em></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div><!-- .col -->
                </div><!-- .row -->
              </div><!-- .col -->
            </div><!-- .row -->
          </div><!-- .container -->
        </section>


        <section class="section section-pricing" id="pricing">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-6 col-md-8 col-sm-10">
                <div class="section-head text-center">
                  <h2 class="title fw-medium">Choose Your Plan</h2>
<p>Select the perfect plan for your business and enjoy customized services tailored to your needs. </p>

                </div><!-- .section-head -->
              </div><!-- .col -->
            </div><!-- .row -->
            <div class="row justify-content-center g-gs">
              <div class="col-xl-4 col-lg-4 col-sm-6">
                <div class="pricing pricing-s3 pricing-s3-featured card card-shadow card-bordered round-xl">
                  <div class="card-inner card-inner-lg">
                    <h4 class="title pb-2 fw-normal">Basic Plan</h4>
                    <h1 class="pb-4 fw-medium">₱ 49.00 <span :style="{fontSize: '24px', letterSpacing: '1px'}"> / Day</span></h1>
                    <ul class="list list-success list-check gy-2">
                      <li><strong>Standard delivery services</strong></li>
                      <li><strong>Basic tracking and updates</strong></li>
                      <li><strong>Regular customer support</strong></li>
                      <li><strong>Access to a range of essential products</strong></li>
                      <li><strong><span class="text-dark">POS System integration</span> for sales transactions</strong></li>
                      <li><strong>Basic reporting features</strong></li>
                      <li><strong>Standard delivery timeframes</strong></li>
                    </ul>
                    <br>
                    <br>
                    <br>
                    <div class="pricing-action pt-5  mt-4">
                      <a href="#" class="btn btn-outline-light btn-lg btn-block mt-3"><span>Buy Plan</span></a>
                    </div>
                  </div>
                </div>
              </div><!-- .col -->
              <div class="col-xl-4 col-lg-4 col-sm-6">
                <div class="pricing pricing-s3 pricing-featured card card-shadow card-bordered round-xl">
                  <div class="card-inner card-inner-lg">
                    <h4 class="title pb-2 fw-normal">Premium Plan</h4>
                    <h1 class="pb-4 fw-medium">₱ 75.00 <span :style="{fontSize: '24px', letterSpacing: '1px'}"> / Day</span></h1>
                    <ul class="list list-success list-check gy-2">
                      <li><strong>Priority delivery for faster service</strong></li>
                      <li><strong>Enhanced tracking and real-time updates</strong></li>
                      <li><strong>Extended customer support hours</strong></li>
                      <li><strong>Personalized delivery options</strong></li>
                      <li><strong>Exclusive discounts on select items</strong></li>
                      <li><strong><span class="text-dark">POS and Inventory System</span> integration for comprehensive management</strong></li>
                      <li><strong>Advanced reporting features</strong></li>
                    </ul>
                    <div class="pricing-action mt-5 pt-4">
                      <a href="#" class="btn btn-outline-light btn-lg btn-block"><span>Buy Plan</span></a>
                    </div>
                  </div>
                </div>
              </div><!-- .col -->
              <div class="col-xl-4 col-lg-4 col-sm-6">
                <div class="pricing pricing-s3 pricing-s3-featured card card-shadow card-bordered round-xl">
                  <div class="card-inner card-inner-lg">
                    <h4 class="title pb-2 fw-normal">Ultimate Plan</h4>
                    <h1 class="pb-4 fw-medium">₱ 98.00 <span :style="{fontSize: '24px', letterSpacing: '1px'}"> / Day</span></h1>
                    <ul class="list list-success list-check gy-2">
                      <li><strong>All features of the Premium Plan</strong></li>
                      <li><strong>Express delivery with top-priority status</strong></li>
                      <li><strong>Advanced analytics and reporting</strong></li>
                      <li><strong>Dedicated account manager for personalized support</strong></li>
                      <li><strong>Customizable delivery solutions</strong></li>
                      <li><strong><span class="text-dark">POS and Inventory System</span> with advanced features for detailed tracking and control</strong></li>
                      <li><strong>Exclusive access to new product lines and special offers</strong></li>
                    </ul>
                    <div class="pricing-action">
                      <a href="#" class="btn btn-outline-light btn-lg btn-block"><span>Buy Plan</span></a>
                    </div>
                  </div>
                </div>
              </div><!-- .col -->
            </div><!-- .row -->
          </div><!-- .container -->
        </section>
        <section class="section section-testimonial bg-white pt-0" id="reviews">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-xl-7 col-md-8 col-10">
                            <div class="section-head">
                                <h2 class="title">This is what our esteemed clients have to say for us</h2>
                            </div>
                        </div><!-- .col -->
                    </div><!-- .row -->
                    <div class="row g-gs justify-content-center">
                        <div class="col-lg-6">
                            <div class="card card-shadow round-xl">
                                <div class="card-inner card-inner-lg">
                                    <div class="review review-s2">
                                        <div class="review-content">
                                            <div class="review-rating rating rating-sm">
                                                <ul class="rating-stars">
                                                    <li><em class="icon ni ni-star-fill"></em></li>
                                                    <li><em class="icon ni ni-star-fill"></em></li>
                                                    <li><em class="icon ni ni-star-fill"></em></li>
                                                    <li><em class="icon ni ni-star-fill"></em></li>
                                                    <li><em class="icon ni ni-star-fill"></em></li>
                                                </ul>
                                            </div>
                                            <h6 class="name mt-2">Aling Rosing Grocery Store</h6>
                                            <div class="review-text">
                                                <p>"Partnering with this service has transformed how we manage our grocery deliveries. The seamless integration with our inventory system has streamlined our operations, and the priority delivery feature ensures our customers get their orders on time. The support team is fantastic, and the advanced analytics help us make informed decisions. Highly recommend for any local grocery store!"" </p>
                                                <h6 class="review-name text-dark"><i>~ Aling Rosing, Owner</i></h6>
                                            </div>
                                        </div>
                                    </div><!-- .review -->
                                </div>
                            </div><!-- .testimonial-block -->
                        </div><!-- .col -->
                        <div class="col-lg-6">
                            <div class="card card-shadow round-xl">
                                <div class="card-inner card-inner-lg">
                                    <div class="review review-s2">
                                        <div class="review-content">
                                            <div class="review-rating rating rating-sm">
                                                <ul class="rating-stars">
                                                    <li><em class="icon ni ni-star-fill"></em></li>
                                                    <li><em class="icon ni ni-star-fill"></em></li>
                                                    <li><em class="icon ni ni-star-fill"></em></li>
                                                    <li><em class="icon ni ni-star-fill"></em></li>
                                                    <li><em class="icon ni ni-star-fill"></em></li>
                                                </ul>
                                            </div>
                                            <h6 class="name mt-2">Dela Cruz Pharmacy</h6>
                                            <div class="review-text">
                                                <p>"The online pharmacy delivery service has been a game-changer for us. It’s so easy to manage prescriptions and over-the-counter sales with the integrated POS system. Our customers appreciate the reliable delivery and the automated refill reminders are a big plus. The support is top-notch, and the service keeps our pharmacy running smoothly. We couldn’t ask for a better partner!"</p>
                                                <h6 class="review-name text-dark"><i>~ Dr. Juan Dela Cruz, Owner</i></h6>
                                            </div>
                                        </div><!-- .review -->
                                    </div>
                                </div>
                            </div><!-- .testimonial-block -->
                        </div><!-- .col -->
                    </div><!-- .row -->
                </div><!-- .container -->
            </section>
        <section class="section section-facts bg-dark" id="facts">
          <div class="container">
            <div class="row g-gs flex-sm-row-reverse justify-content-center align-items-center">
              <div class="col-md-6 col-sm-3 text-sm-center is-dark">
                <a class="play popup-video" href="https://www.youtube.com/watch?v=SSo_EIwHSd4">
                  <div class="styled-icon styled-icon-6x styled-icon-s5 text-white">
                    <svg x="0px" y="0px" viewBox="0 0 512 512" style="fill:currentColor" xml:space="preserve">
                      <path d="M436.2,178.3c-7.5-4.7-17.4-2.4-22.1,5.1c-4.7,7.5-2.4,17.4,5.1,22.1c17.5,10.9,28,29.8,28,50.4s-10.5,39.5-28,50.4
	L155.7,470.7c-18.6,11.6-41.1,12.2-60.3,1.5c-19.2-10.6-30.6-30.1-30.6-52V91.7c0-21.9,11.4-41.3,30.6-52
	c19.1-10.6,41.7-10.1,60.3,1.5l153.4,95.6c7.5,4.7,17.4,2.4,22.1-5.1c4.7-7.5,2.4-17.4-5.1-22.1L172.7,14
	c-28.6-17.9-63.3-18.7-92.9-2.4C50.3,28.1,32.7,58,32.7,91.7v328.6c0,33.7,17.6,63.7,47.1,80c14.1,7.8,29.3,11.7,44.5,11.7
	c16.7,0,33.4-4.7,48.4-14l263.5-164.3c27-16.8,43.1-45.9,43.1-77.7S463.2,195.2,436.2,178.3z" />
                    </svg>
                  </div>
                </a>
              </div>
              <div class="col-md-6 col-sm-9">
                <div class="text-block is-dark pe-xl-5">
                  <h6 class="title-xs fs-15px text-purple">Our's Achievement</h6>
                  <h3 class="title">2,578+ Orders Completed: Successfully delivered Orders nationwide.</h3>
                </div>
                <div class="row g-gs">
                  <div class="col-sm-4 col-6">
                    <div class="facts facts-s2">
                      <div class="facts-card">
                        <div class="h2 fw-bold text-danger pb-1">2065</div>
                        <div class="h6 fw-normal text-white">Clients</div>
                      </div>
                    </div><!-- .facts -->
                  </div><!-- .col -->
                  <div class="col-sm-4 col-6">
                    <div class="facts facts-s2">
                      <div class="facts-card">
                        <div class="h2 fw-bold text-success pb-1">5043</div>
                        <div class="h6 fw-normal text-white">Members</div>
                      </div>
                    </div><!-- .facts -->
                  </div><!-- .col -->
                  <div class="col-sm-4 col-6">
                    <div class="facts facts-s2">
                      <div class="facts-card">
                        <div class="h2 fw-bold text-purple pb-1">245</div>
                        <div class="h6 fw-normal text-white">Orders Today</div>
                      </div>
                    </div><!-- .facts -->
                  </div><!-- .col -->
                </div><!-- .row -->
              </div><!-- .col -->
            </div><!-- .row -->
          </div><!-- .container -->
          <div class="bg-image bg-contain bg-right bg-overlay bg-overlay-dark-transparent d-none d-md-block">
            <img src="/images/bg/a-half.jpg" alt="">
          </div>
        </section>

        


            <section class="section section-faq" id="faqs">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-lg-12">
                            <div class="section-head">
                                <h2 class="title">Frequently Asked Questions</h2>
                                <p>Got a question? We've got answers. If you have some other questions, Who are in extremely love with eco friendly system.</p>
                            </div><!-- .section-head -->
                        </div><!-- .col -->
                    </div><!-- .row -->
                    <div class="row justify-content-center">
                        <div class="col-lg-12">
                          <div class="accordion accordion-s1 card card-shadow border-0 round-xl" id="accordion">
    <div class="accordion-item">
        <a href="#" class="accordion-head" data-bs-toggle="collapse" data-bs-target="#accordion-item-1">
            <h6 class="title">Is your software secure for use?</h6>
            <span class="accordion-icon"></span>
        </a>
        <div class="accordion-body collapse show" id="accordion-item-1" data-bs-parent="#accordion">
            <div class="accordion-inner">
                <p>Yes, our software is designed with the highest security standards in mind. We implement robust encryption and secure data handling practices to ensure your information is protected.</p>
            </div>
        </div>
    </div><!-- .accordion-item -->
    <div class="accordion-item">
        <a href="#" class="accordion-head collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-item-2">
            <h6 class="title">What types of services do you offer?</h6>
            <span class="accordion-icon"></span>
        </a>
        <div class="accordion-body collapse" id="accordion-item-2" data-bs-parent="#accordion">
            <div class="accordion-inner">
                <p>We offer a range of tailored software solutions including POS systems, inventory management, and customized software development. Our services are designed to meet the specific needs of your business.</p>
            </div>
        </div>
    </div><!-- .accordion-item -->
    <div class="accordion-item">
        <a href="#" class="accordion-head collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-item-3">
            <h6 class="title">How long does it take to implement your software?</h6>
            <span class="accordion-icon"></span>
        </a>
        <div class="accordion-body collapse" id="accordion-item-3" data-bs-parent="#accordion">
            <div class="accordion-inner">
                <p>Implementation times vary depending on the complexity of the software and your specific requirements. Typically, we complete the process within a few weeks to ensure a smooth transition.</p>
            </div>
        </div>
    </div><!-- .accordion-item -->
    <div class="accordion-item">
        <a href="#" class="accordion-head collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-item-4">
            <h6 class="title">Can I customize the software for my business?</h6>
            <span class="accordion-icon"></span>
        </a>
        <div class="accordion-body collapse" id="accordion-item-4" data-bs-parent="#accordion">
            <div class="accordion-inner">
                <p>Yes, our software is highly customizable to fit the unique needs of your business. We work closely with you to tailor features and functionalities to meet your specific requirements.</p>
            </div>
        </div>
    </div><!-- .accordion-item -->
    <div class="accordion-item">
        <a href="#" class="accordion-head collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-item-5">
            <h6 class="title">What support do you provide after implementation?</h6>
            <span class="accordion-icon"></span>
        </a>
        <div class="accordion-body collapse" id="accordion-item-5" data-bs-parent="#accordion">
            <div class="accordion-inner">
                <p>We offer comprehensive support including training, technical assistance, and regular updates. Our support team is available to help with any issues or questions you may have post-implementation.</p>
            </div>
        </div>
    </div><!-- .accordion-item -->
</div>
<!-- accordion -->
                        </div><!-- .col -->
                    </div><!-- .row -->
                </div><!-- .container -->
            </section><!-- .section -->
            <section class="section secton-cta bg-lighter">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-10">
                            <div class="row align-items-center g-0">
                                <div class="col-md-12">
                                    <div class="card card-shadow round-xl">
                                        <div class="card-inner card-inner-lg">
                                            <div class="form-block">
                                                <div class="section-head section-head-sm">
                                                    <h4 class="title">Do you have any other question?</h4>
                                                </div>
                                                <form action="#" class="form-submit">
                                                    <div class="row g-4">
                                                        <div class="col-12">
                                                            <div class="form-group ">
                                                                <label class="form-label" for="name">Your Name</label>
                                                                <div class="form-control-wrap">
                                                                    <input type="text" class="form-control form-control-lg" id="name" placeholder="Your Name">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label class="form-label" for="email">Enter Your Email</label>
                                                                <div class="form-control-wrap">
                                                                    <input type="text" class="form-control form-control-lg" id="email" placeholder="Enter Your Email">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label class="form-label" for="question">Your Question</label>
                                                                <div class="form-control-wrap">
                                                                    <textarea class="form-control no-resize" id="question" placeholder="Your Question"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <a href="#" class="btn btn-lg btn-primary">Ask Question</a>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div><!-- .col -->
                            </div><!-- .row -->
                        </div><!-- .col -->
                    </div><!-- .row -->
                </div><!-- .container -->
            </section>

        <footer class="footer bg-white" id="footer">
          <div class="container">
            <div class="row g-3 align-items-center justify-content-md-between py-4 py-md-5">
              <div class="col-md-3">
                <div class="footer-logo">
                  <a href="html/index.html" class="logo-link">
                    <img class="logo-light logo-img" src="/logo.png" srcset="/images/logo2x.png 2x" alt="logo">
                    <img class="logo-dark logo-img" src="/images/logo-dark.png" srcset="/images/logo-dark2x.png 2x"
                      alt="logo-dark">
                  </a>
                </div><!-- .footer-logo -->
              </div><!-- .col -->
              <div class="col-md-9 d-flex justify-content-md-end">
                <ul class="link-inline gx-4">
                  <li><a href="#">How it works</a></li>
                  <li><a href="#">Service</a></li>
                  <li><a href="#">Help</a></li>
                  <li><a href="#">Contact</a></li>
                </ul><!-- .footer-nav -->
              </div><!-- .col -->
            </div>
            <hr class="hr border-light mb-0 mt-n1">
            <div class="row g-3 align-items-center justify-content-md-between py-4">
              <div class="col-md-8">
                <div class="text-base">Copyright &copy; 2024 talipapa. Developed by <a class="text-base fw-bold"
                    href="#">dcodex.io</a></div>
              </div><!-- .col -->
              <div class="col-md-4 d-flex justify-content-md-end">
                <ul class="social">
                  <li><a href="#"><em class="icon ni ni-twitter"></em></a></li>
                  <li><a href="#"><em class="icon ni ni-facebook-f"></em></a></li>
                  <li><a href="#"><em class="icon ni ni-instagram"></em></a></li>
                  <li><a href="#"><em class="icon ni ni-pinterest"></em></a></li>
                </ul><!-- .footer-icon -->
              </div><!-- .col -->
            </div><!-- .row -->
          </div><!-- .container -->
        </footer><!-- .footer -->
      </div>
      <!-- main @e -->
    </div>
    <!-- app-root @e -->
    <!-- JavaScript -->
    <!--  -->
  </body>

  </html>
</template>

<script>
import HeadLayout from './components/layout/HeadLayout.vue'
import HeaderLayout from './components/layout/HeaderLayout.vue'

export default {
  name: 'App',
  components: {
    HeadLayout,
    HeaderLayout,
  }
}
</script>
